/**
 * This should be minified later.
 */

Drupal.behaviors.rosenannika = {
  
  share_window: undefined,
  active_size: undefined,
  share_box: undefined,
  share_box_container: undefined,
  share_box_mini_container: undefined,
  share_box_active: undefined,
  old_window_width: undefined,
  image_boxes: undefined,
  
  attach: function(context, settings) {
    
    // initialisers
    this.share_box_init(context);
    this.collapsible(context);
    this.image_boxes_init(context);
    
    // call callbacks needed to be called
    this.resize();
    
    // register listeners
    jQuery(window).on('resize', function(event) { Drupal.behaviors.rosenannika.resize(); });
    
    
    // submodule launchs
    if (this.gallery !== undefined) {
      this.gallery.init(context, settings);
    }
  },
  
  // share link clicked handler
  share_link_callback: function (event) {
    
    // prevent default href following!
    event.preventDefault();
    
    // get href
    var href = jQuery(event.target).attr('href');
    
    var dialog_width = 500;
    
    if (screen.width < dialog_width) { dialog_width = screen.width; }
    
    // launch window thing with share box!
    this.share_window = window.open(href, 'share_window', 'status=0,height=500,width=' + dialog_width + ',resizeable=0');
  },
  
  
  share_box_init: function(context) {
    // attach a click catcher to social media share icons!
    jQuery('.social-media-share a', context).on('click tap', Drupal.behaviors.rosenannika.share_link_callback);
    
    this.share_box = jQuery('.social-media-share');
    this.share_box_container = jQuery('.node-content-sidebar');
    this.share_box_mini_container = jQuery('.node-content-body');
    this.share_box_active = 'maxi';
    
  },
  
  
  image_boxes_init: function(context) {
    this.image_boxes = jQuery('.field-name-field-image-box');
  },
  
  
  // init collapsible things.
  collapsible: function(context) {
    jQuery(context).on('click', '[data-toggle="collapse"]', Drupal.behaviors.rosenannika.collapsible_callback);
  },
  
  
  resize: function() {
    
    var window_width = jQuery(window).width();
    
    /**
     * Collapsible collapse / uncollapse
     */
    
    // collapsible
    if (window_width < 860 && this.active_size !== 'phone-landscape') {
      jQuery('.collapse-collapse').removeClass('collapse-expanded').addClass('collapse-collapsed');
      this.active_size = 'phone-landscape';
    }
    
    // collapsible
    if (window_width >= 860 && this.active_size !== 'tablet-portrait') {
      jQuery('.collapse-collapse').removeClass('collapse-collapsed').addClass('collapse-expanded');
      this.active_size = 'tablet-portrait';
    }
    
    
    /**
     * Move share box
     */
    if (window_width < 700 && this.share_box_active !== 'mini') {
      console.log('make mini');
      this.share_box.prependTo(this.share_box_mini_container);
      this.share_box_active = 'mini';
    }
    
    if (window_width >= 700 && this.share_box_active !== 'maxi') {
      console.log('make maxi');
      this.share_box.prependTo(this.share_box_container);
      this.share_box_active = 'maxi';
    }
    
    /**
     * Image box fields height adjust & h2 margin-top adjust
     */
    
    if (this.old_window_width != window_width) {
      
      var image_boxes_max_height = 0;
      
      this.image_boxes.each( function (index) {
        
        var content = jQuery(this).find('.node-image-box-content');
        var box_height = 0;
        
        content.find('h2, p').each( function (index) {
          box_height += jQuery(this).outerHeight((jQuery(this).prop('tagName') == 'P'));
        });
        
        if (box_height > image_boxes_max_height) { image_boxes_max_height = box_height; }
        
      });
      
      console.log(image_boxes_max_height);
      
      this.image_boxes.find('.node-image-box-content').css({height: image_boxes_max_height});
      
      
    }
    
    
    /**
     * Adjust "previous" properties
     */
    
    this.old_window_width = window_width;
    
  },
  
  
  // callback for collapse!
  collapsible_callback: function (event) {
    
    var origin = jQuery(event.target);
    
    // get options
    var duration = origin.attr('data-collapse-duration');
    if (duration === undefined) { duration = 0; } else { duration = duration * 1000; }
    
    var target_selector = origin.attr('data-target');
    var target = jQuery(target_selector);
    
    // only change if not already in the loop.
    if (!target.hasClass('collapse-collapsing')) {
      
      if (target.hasClass('collapse-collapsed')) {
        target.removeClass('collapse-collapsed').addClass('collapse-expanded collapse-collapsing');
        setTimeout(function() { target.removeClass('collapse-collapsing'); }, duration);
        return true;
      }
      
      if (target.hasClass('collapse-expanded')) {
        target.removeClass('collapse-expanded').addClass('collapse-collapsed collapse-collapsing');
        setTimeout(function() { target.removeClass('collapse-collapsing'); }, duration);
        return true;
      }
      
    }
  }
};


/**
 * This should be minified later.
 */

Drupal.behaviors.rosenannika.gallery = {
  
  gallery: false,
  viewer: false,
  viewer_container: false,
  viewer_slides: false,
  active_slide: false,
  
  init: function (context, settings) {
    
    // create viewer
    this.viewer = jQuery('<div class="aeo-gallery aeo-gallery-hidden"></div>').prependTo('body');
    this.viewer_container = jQuery('<ul class="aeo-gallery-items"></ul>').appendTo(this.viewer);
    this.viewer_slides = [];
    
    // register listener
    this.gallery = jQuery('.gallery li');
    this.gallery.on('touchstart click', Drupal.behaviors.rosenannika.gallery.gallery_click_callback);
    
    
  },
  
  gallery_click_callback: function (event) {
    Drupal.behaviors.rosenannika.gallery._gallery_click_callback(event, this);
  },
  
  _gallery_click_callback: function (event, object) {
    
    
    
  },
  
  // actually load a slide.
  load_slide: function () {
    
  },
  
  // display a slide
  show_slide: function() {
    
  },
  
  // switch to next slide.
  next_slide: function() {
    
  },
  
  // switch to previous slide.
  prev_slide: function() {
    
  },
  
  // create an empty viewer slide. // THIS SHOULD GET SUPPORT FOR LOCATING SLIDE IN SOME WAY ...
  create_empty_slide: function () {
    
    var new_slide = jQuery('<li class="aeo-gallery-slide"></li>');
    
    // logic to get the bloody slide in the right spot. How?
    
    
    
  }
  
};

/*
  
  gallery thingis.
  
  li > article.node.node-image.node-teaser > a:href:"/bildgalleri/bild-12" > figure.field.field-name-field-gallery-image.field-type-image img
  
  */